.ImageBall-AboutPage {
    width : 100%
}

@media (max-width: 1100px) {
    .ImageBall-AboutPage {
        width : 50%
    }
}

@media (max-width: 600px) {
    .ImageBall-AboutPage {
        width : 30%
    }
}
